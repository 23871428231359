import { useEffect, useState } from "react";
import { barDefault, boardDefault } from "../common/initAnnouncement";
import { barRef, boardRef } from "../config/firebaseRef";

function useAnnouncement(user, db) {
  const [bar, setBar] = useState(barDefault);
  const [board, setBoard] = useState(boardDefault);
  useEffect(() => {
    if (!user?.user_id)
      return;
    const barSub = barRef(user?.user_id, db)
      .onSnapshot(snapshot => {
        if (!snapshot.exists)
          return;
        const barData = snapshot.data();
        if (!barData.highlight) {
          barData.highlight = barDefault.highlight;
        }
        if (barData.geniamNews === undefined) {
          barData.geniamNews = true;
        }
        setBar(barData);
      });
    const boardSub = boardRef(user?.user_id, db)
      .onSnapshot(snapshot => {
        if (!snapshot.exists)
          return;
        setBoard(snapshot.data());
      });
    return () => {
      if (barSub) barSub();
      if (boardSub) boardSub();
    };
  }, [user?.user_id]);
  return { bar, board };
}

export default useAnnouncement;
