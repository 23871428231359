import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Lock, Settings } from "@material-ui/icons";
import { Popover } from "antd";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import useAnnouncement from "../../hooks/useAnnouncement";
import { recommendCollRef } from "../../config/firebaseRef";
import styles from "../../styles/RecommendAnnounceBar.module.scss";

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  swipeToSlide: true,
  autoplay: true,
  autoplaySpeed: 1200,
  adaptiveHeight: true,
  arrows: false,
  beforeChange: function(currentSlide, nextSlide) {
    // console.log("before change", currentSlide, nextSlide);
  }
};

function RecommendAnnounceBar({
                                user,
                                db,
                                lock = true,
                                handleGotoSettings,
                              }) {
  const ref = useRef(null);
  const [data] = useCollectionDataOnce(recommendCollRef(db)
    .where("isApp", "==", true)
    .where("appIds", "array-contains", 6)
  );

  let recommends = [];
  if (data && data.length > 0) {
    const now = Date.now();
    recommends = data.filter(recommend => {
      if (recommend.scheduled) {
        if (recommend.startTime < now) {
          recommend.enabled = true;
        } else if (recommend.endTime !== void 0 && recommend.endTime > 0 && recommend.endTime > now) {
          recommend.enabled = false;
        }
      }
      return !!recommend.enabled;
    })
  }

  const { bar } = useAnnouncement(user, db);
  const [displayAnnounce, setDisplayAnnounce] = useState(true);
  useEffect(() => {
    setDisplayAnnounce(true);
    setTimeout(() => {
      if (!lock && bar.defaultRotation) {
        setDisplayAnnounce(false);
      } else {
        setDisplayAnnounce(true);
      }
    }, 5000);
  }, [bar.defaultRotation, lock]);
  if (!isNaN(bar.rotationInterval)) {
    settings.autoplaySpeed = bar.rotationInterval * 1000;
  }
  if (!user?.user_id) return null;
  if (!recommends || recommends?.length === 0 || !bar.rotationInterval || !bar.announcementBar || !displayAnnounce) return null;
  return (
    <div className={styles.root}>
      {
        recommends?.length > 1 ?
          <Slider {...settings} className={styles.slideContainer} ref={ref}>
            {recommends.map((re, index) => {
              if (!re)
                return null;
              return (<div className={styles.recommendBarItem} key={index}>
                <div className={styles.ItemContent}
                >
                  <div className={styles.centerContent}>
                    {
                      re.img ?

                        <Popover placement={"rightBottom"}
                                 content={<img src={re.img}
                                               onClick={() => {
                                                 if (re.link)
                                                   return window.open(re.link, "_bank");
                                               }}
                                               className={styles.viewImg}
                                               alt={""} />}
                        >
                          <img src={re.img} onClick={() => {
                            if (re.link)
                              return window.open(re.link, "_bank");
                          }} className={styles.recommendImg}
                               alt={""} />
                        </Popover>
                        :
                        null
                    }
                    <div onClick={() => {
                      if (re.link)
                        return window.open(re.link, "_bank");
                    }}>{re.title} {re.content ? `- ${re.content}` : ""} </div>
                    {
                      lock &&
                      <Lock className={styles.lockIcon}
                            onClick={handleGotoSettings}
                      />
                    }
                  </div>

                  <button className={styles.setting}
                          onClick={handleGotoSettings}
                  >
                    <Settings />
                  </button>
                </div>
              </div>);
            })}
          </Slider>
          :
          <div className={styles.recommendBarItem}>
            <div className={styles.ItemContent}
            >
              <div className={styles.centerContent}>
                {
                  recommends[0]?.img ?

                    <Popover placement={"rightBottom"}
                             content={<img src={recommends[0]?.img}
                                           onClick={() => {
                                             if (recommends[0]?.link)
                                               return window.open(recommends[0]?.link, "_bank");
                                           }}
                                           className={styles.viewImg}
                                           alt={""} />}
                    >
                      <img onClick={() => {
                        if (recommends[0]?.link)
                          return window.open(recommends[0]?.link, "_bank");
                      }} src={recommends[0]?.img} className={styles.recommendImg}
                           alt={""} />
                    </Popover>
                    :
                    null
                }
                <div onClick={() => {
                  if (recommends[0]?.link)
                    return window.open(recommends[0]?.link, "_bank");
                }}>{recommends[0]?.title} {recommends[0]?.content ? `- ${recommends[0]?.content}` : ""} </div>
                {
                  lock &&
                  <Lock className={styles.lockIcon}
                        onClick={handleGotoSettings}
                  />
                }
              </div>
              <button className={styles.setting}
                      onClick={handleGotoSettings}
              >
                <Settings />
              </button>
            </div>
          </div>
      }
    </div>
  );
}

export default RecommendAnnounceBar;

